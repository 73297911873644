










import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class LazyImage extends Vue {
  @Prop() private readonly id!: number
  @Prop() private readonly src!: string
  private realSrc: string | null = null
  private loadingImage = require('@/assets/images/loading.gif')
  private intersectionObserver: IntersectionObserver | null = null

  private mounted(): void {
    this.intersectionObserver = new IntersectionObserver(
      entries => {
        if (entries[0].intersectionRatio <= 0) {
          this.realSrc = null
        } else {
          this.realSrc = this.src
        }
      },
      {
        root: document.querySelector('#container'),
      }
    )
    const image = this.$refs['img-' + this.id] as Element
    this.intersectionObserver.observe(image)
  }

  private beforeDestroy(): void {
    const image = this.$refs['img-' + this.id] as Element
    if (this.intersectionObserver) {
      this.intersectionObserver.unobserve(image)
    }
  }
}
