




















































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import debounce from 'lodash/debounce'
import filterGroup from '@/components/filterGroup'
import moment from 'moment'
import infiniteScroll from 'vue-infinite-scroll'
import { i18n } from '@/i18n/i18n'
import LazyImage from '@/components/LazyImage.vue'
import { MomentsController, CommonController } from '@/services/request.service'
// import InfiniteList from "@/components/InfiniteList"
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    filterGroup,
    LazyImage,
    // InfiniteList
  },
  directives: {
    infiniteScroll,
  },
})
export default class Moment extends Vue {
  private getAllDataFlag: boolean = false
  private loading: boolean = false
  private translationVisible: boolean = false
  private contentExpand: boolean = false
  private pagination: any = createPagination({
    current: 0,
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private isTop: boolean = true
  private document = document
  private data: Array<any> = []
  private actions: Array<any> = [{ type: 'like', text: '' }]
  private filter: any = {
    date: undefined,
  }
  private get isChildRoute(): boolean {
    return this.$route.name !== 'moment'
  }
  private get userId(): any {
    return this.$store.state.memberId
  }
  private get userName(): any {
    return this.$store.state.username
  }
  private get locale(): any {
    return this.$store.state.locale
  }
  private options: any = {
    url: 'data-source',
  }

  private onScroll(params): void {
    let scrollTop = params.currentTarget.scrollTop
    this.isTop = scrollTop === 0
  }

  private changeName = debounce(() => {
    this.refresh()
  }, 500)

  private fetchData(cb): void {
    let { current, pageSize } = this.pagination
    let { date } = this.filter
    let condition = {
      pageCurrent: current,
      pageSize: pageSize || this.pagination.defaultPageSize,
      start: (date || [])[0] ? (date || [])[0].valueOf() : undefined,
      end: (date || [])[1] ? (date || [])[1].valueOf() : undefined,
    }
    MomentsController.getMoments(
      current,
      pageSize,
      (date || [])[0] ? (date || [])[0].valueOf() : undefined,
      (date || [])[1] ? (date || [])[1].valueOf() : undefined
    )
      .then(res => {
        cb(res)
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
  }

  private handleInfiniteOnLoad(): void {
    if (this.loading || this.getAllDataFlag) return
    // return
    const data = this.data
    this.loading = true
    this.pagination.current++
    this.fetchData(res => {
      this.pagination.total = res.data.totalItem
      this.data = data.concat(res.data.items).map((item, index) => {
        let isLike = item.favors.filter(item => item.userId === this.userId).length !== 0
        return {
          ...item,
          index,
          date: moment(item.time).format('YYYY.MM.DD HH:mm'),
          isLike,
          translateVisible: false,
          allPhotoVisible: false,
          contentExpand: false,
        }
      })
      this.loading = false
      if (this.data.length >= res.data.totalItem) {
        this.getAllDataFlag = true
      }
    })
  }

  private translate(item): void {
    if (item.translateVisible) {
      item.translateVisible = false
    } else {
      CommonController.translate(item.content).then(res => {
        this.translationVisible = true
        this.$set(item, 'translate', res.data.value)
      })
      item.translateVisible = true
    }
  }

  private changeLike(item): void {
    MomentsController.favor(!item.isLike, item.momentId)
      .then(res => {
        if (item.isLike) {
          let index = item.favors.findIndex(favor => this.userId === favor.userId)
          item.favors.splice(index, 1)
        } else {
          item.favors.push({
            userId: this.userId,
            favorName: this.userName,
          })
        }
        item.isLike = !item.isLike
      })
      .catch(err => console.log(err))
  }

  private calcExpand(): void {
    this.$nextTick(() => {
      this.data.forEach((item, index) => {
        if (item.expandClickShow) return
        this.$set(item, 'expandClickShow', false)
        let el = document.getElementById(`momentTextContent_${index}`)
        if (!el) return
        let cw = el.clientWidth
        let sw = el.scrollWidth
        if (sw > cw) {
          this.$set(item, 'expandClickShow', true)
        }
      })
    })
  }

  private updated() {
    this.calcExpand()
  }

  private mounted() {
    // this.calcExpand()
    let el = document.getElementById('moment_root') || ({} as any)
    this.clearFilter()
    this.refresh()
    el.onscroll = this.onScroll
  }

  public toTop(): void {
    let momentList = document.getElementById('moment_root')
    momentList ? (momentList.scrollTop = 0) : ''
  }

  private avatarFilter(students: Array<any>): Array<any> {
    const newStudents = students.slice(0, 3)
    const avatars = newStudents.map(student => student.studentAvatarUrl)
    return avatars
  }

  private refresh(): void {
    this.pagination.current = 0
    this.getAllDataFlag = false
    this.isTop = true
    this.data = []
    this.handleInfiniteOnLoad()
  }

  private clearFilter(): void {
    this.filter = {
      date: undefined,
    }
  }
}
